<template>
  <div class="newdalite">
    <div class="indexTopBox" :style="{ backgroundImage: `url(${Topimg})` }">
      <div class="indexTop">
        <div class="header">
          <div class="logo">
            <img :src="topList.logo" alt="" srcset="">
          </div>
          <div class="navigationBox ">
            <div v-for="item in topList.nav" :key="item.id" class="navigation  navigation1">
              <a @click="toAim(item.id)">{{ item.title }}</a>
            </div>
          </div>
        </div>
        <div class="introduction">
          <div class="nameBox">
            {{ topList.msg.name }}
          </div>
          <div class="keywords">
            <span v-for="eles in desc" :key="eles" class="span">{{
              eles
            }}</span>
          </div>
          <div class="descBox">
            {{ topList.msg.desc }}
          </div>
        </div>
      </div>
    </div>
    <div id="contentBox" class="contentBox">
      <div class="leftBox">
        <div class="search">
          <i class="el-icon-search" />
          <el-input v-model="input" size="small" placeholder="请输入关键字" @keyup.enter.native="entersearch" />
        </div>
        <div class="classification">
          <div class="title">新闻类型</div>
          <div v-for="item in classification" :key="item.id" class="fenlei" @click="gonewfenl(item.id)">
            {{ item.title }} ({{ item.count }})
          </div>
        </div>
        <div class="hotList">
          <div class="title">热门文章</div>
          <div v-for="item in hotlist" :key="item.id" class="hottext" @click="hostlist(item.id)">
            <div class="imgBox">
              <img :src="item.image" alt="" srcset="">
            </div>
            <div class="jiesao">
              <div class="titles">
                {{ item.title }}
              </div>
              <div class="timeBox">
                {{ item.create_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 详情 -->
      <div v-if="pages === 'details'" class="rightBox">
        <div class="details">
          <div class="tltle">
            {{ newdetails.title }}
          </div>
          <div class="icoust">
            <div class="timeBox">{{ newdetails.create_time }}</div>
            <div v-if="newdetails.origin" class="laiyuan">
              来源:{{ newdetails.origin }}
            </div>
          </div>
          <div class="content" v-html="newdetails.content" />
        </div>
        <div class="article">
          <div class="up_article" @click="getnewsdetail(newdetails.shang.id)">
            <div v-if="newdetails.shang.id">
              <div class="text">上一篇</div>
              <div>{{ newdetails.shang.title }}</div>
            </div>
          </div>
          <div v-if="newdetails.xia.id" class="below_article" @click="getnewsdetail(newdetails.xia.id)">
            <div class="text">下一篇</div>
            <div>{{ newdetails.xia.title }}</div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div v-if="pages === 'classification'" class="rightBox1">
        <div v-for="item in articlelist" :key="item.id" class="fenlsd" @click="hostlist(item.id)">
          <div class="imgBox">
            <img :src="item.image" alt="">
          </div>
          <div class="text">
            <span class="span">{{ item.create_time }}</span>
            <span v-if="item.origin" class="span">{{ item.origin }}</span>
          </div>
          <div class="nameBox">
            {{ item.title }}
          </div>
          <div class="descBox">
            {{ item.desc }}
          </div>
        </div>
        <div v-if="articlelist.length===0" class="nocontent">
          <h4>暂无相关内容~~</h4>
        </div>
        <div class="paginationBox">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :page-size="4"
            :hide-on-single-page="ishide"
            :pager-count="7"
            :current-page="issearch?page1:page"
            @current-change="handleSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="bottomBox" :style="{ backgroundImage: `url(${topList.msg.backimage})` }">
      <div class="bottom">
        <div class="logo">
          <img :src="topList.logo" alt="" srcset="">
        </div>
        <div class="bottomBox2">
          <div class="imgBox">
            <img :src="topList.msg.code" alt="">
            <div class="text1">扫码关注</div>
          </div>
          <div class="addressBox">
            <div class="add">{{ topList.msg.address }}</div>
            <div class="add">{{ topList.msg.email }}</div>
            <div class="add">{{ topList.msg.phone }}</div>
          </div>
        </div>
        <div class="copyright">
          {{ topList.msg.copyright }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getnewsdetail,
  getindex,
  getclassification,
  gethotlists,
  getarticlelist,
  getsearch
} from '@/api/axiospull.js';
export default {
  data() {
    return {
      classification: '',
      input: '',
      topList: {},
      Topimg: '',
      hotlist: '',
      newdetails: [],
      pages: '',
      articlelist: {},
      limit1: 4,
      page1: 1,
      limit: 4,
      page: 1,
      total: '',
      ishide: true,
      issearch: false
    };
  },
  created() {
    // this.id = this.$route.query.id;
    console.log(this.$route.query);
    this.pages = this.$route.query.page;
    this.getnewsdetail(this.$route.query.id);
    this.getindex();
    this.getclassification();
    this.gethotlists();
    this.getarticlelist(this.$route.query.id);
    setTimeout(() => {
      const elm = document.getElementById('contentBox');
      elm.scrollIntoView(true);
    }, 100);
  },
  methods: {
    handleSizeChange(val) {
      console.log(`当前页: ${val}`);

      if (this.issearch) {
        this.page1 = val;
        const select1 = localStorage.getItem('keywords');
        this.getsearch(select1);
      } else {
        this.page = val;
        const select = localStorage.getItem('cate_id');
        this.getarticlelist(select);
      }
    },
    entersearch() {
      console.log(this.input);
      this.page1 = 1;
      this.getsearch(this.input);
      this.pages = 'classification';
      // this.$router.push({ path: '/newdalite', query: { page: 'classification' }});
    },
    gonewfenl(e) {
      console.log(e);
      this.page = 1;
      this.pages = 'classification';
      this.getarticlelist(e);
      this.$router.push({ path: '/newdalite', query: { id: e, page: 'classification' }});
      const elm = document.getElementById('contentBox');
      elm.scrollIntoView(true);
      this.issearch = false;
    },
    toAim(e) {
      localStorage.setItem('anchorId', `demo${e}`);
      this.$router.push({
        name: 'Index' // 跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
    hostlist(e) {
      this.pages = 'details';
      this.$router.push({ path: '/newdalite', query: { id: e, page: 'details' }});
      this.getnewsdetail(e);
      const elm = document.getElementById('contentBox');
      elm.scrollIntoView(true);
    },
    // http://cms.com/api/index/get_article_keywords?keywords=里海·泸州数字农业公园&limit=10&page=1
    //  搜索
    async getsearch(e) {
      const res = await getsearch({ keywords: e, limit: this.limit1, page: this.page1 });
      this.articlelist = res.data.data.data;
      console.log('文章fl', this.articlelist);
      this.total = res.data.data.count;
      localStorage.setItem('keywords', e);
      this.issearch = true;
    },
    //  新闻列表
    async getarticlelist(e) {
      const res = await getarticlelist({ cate_id: e, limit: this.limit, page: this.page });
      this.articlelist = res.data.data.data;
      this.total = res.data.data.count;
      console.log('文章fl', res.data.data);
      localStorage.setItem('cate_id', e);
    },
    // 热门文章
    async gethotlists() {
      const res = await gethotlists();
      this.hotlist = res.data.data;
    },
    // 新闻分类
    async getclassification() {
      const res = await getclassification();
      this.classification = res.data.data;
    },
    // 新闻详情
    async getnewsdetail(e) {
      const res = await getnewsdetail({ id: e });
      this.newdetails = res.data.data;
      console.log('新闻详情', this.newdetails);
    },
    // 公司简介
    async getindex() {
      const res = await getindex();
      this.topList = res.data.data;
      this.desc = this.topList.msg.keywords.split(',');
      this.Topimg = this.topList.backgroud[0].filepath;
    }
  }
};
</script>

<style lang="less">
.newdalite {
  width: 100%;

  .bottomBox {
    width: 100%;
    //  border: 1px solid blue;
    height: 33.28rem;
    background-size: 100% 33.28rem;

    .bottom {
      width: 26.25rem;
      // margin: 45px 360px;
      // border: 1px solid yellow;
      height: 20.4675rem;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .logo {
        width: 16.875rem;
        height: 3.3281rem;
        margin: 0 4.6875rem;
      }

      .bottomBox2 {
        margin-top: 3.25rem;
        display: flex;
        justify-content: space-between;

        .imgBox {
          width: 7rem;

          img {
            width: 7rem;
            height: 7rem;
          }

          .text1 {
            text-align: center;
            font-size: 0.875rem;
            color: #ffffff;
          }
        }

        .addressBox {
          font-size: 1rem;
          color: #ffffff;

          .add {
            margin: 21px 0;
          }

          .add:nth-child(1) {
            margin-top: 0;
          }
        }
      }

      .copyright {
        text-align: center;
        color: #ffffff;
        font-size: 1rem;
        position: absolute;
        bottom: 0;
        left: 3.5625rem;
      }
    }
  }

  .indexTopBox {
    width: 100%;
    height: 67.5rem;
    // border: 1px solid transparent;
    background-size: 100% 67.5rem;

    .indexTop {
      width: 108rem;
      height: 60.75rem;
      // border: 1px solid blue;
      margin: 3.375rem 6rem;
      margin-top: 0;

      .header {
        width: 100%;
        display: flex;

        .logo {
          width: 16.875rem;
          height: 3.3281rem;
          // border: 1px solid yellow;
        }

        .navigationBox {
          margin-left: 9.1875rem;
          width: 81.4375rem;
          // border: 1px solid yellow;
          overflow: hidden;

          .navigation {
            float: left;
            font-size: 1.25rem;
            color: #ffffff;
            margin-left: 7rem;
            height: 1.875rem;
            line-height: 1.875rem;
            // border: 1px solid red;
            padding: 0 0.3125rem;
            border-radius: 0.3125rem;
            margin-top: 0.7188rem;

            a {
              text-decoration: none;
              color: #ffffff;
            }
          }

          .navigation:nth-child(6) {
            background-color: #1c316d;
          }

          .navigation:nth-child(1) {
            margin-left: 0px;
          }

          .navigation:hover {
            cursor: pointer;
            background-color: #1c316d;
          }
        }
      }

      .introduction {
        width: 44.375rem;
        height: 25rem;
        // border: 1px solid yellow;
        margin-top: 15.6875rem;

        .nameBox {
          // height: 200px;
          color: #ffffff;
          font-size: 1.25rem;
          font-weight: bold;
          height: 2.5rem;
          border-bottom: 2px solid #ffffff;
          line-height: 2.5rem;
        }

        .keywords {
          font-size: 4.625rem;
          font-weight: bold;
          color: #ffffff;

          .span:nth-child(2) {
            margin-left: 3.1875rem;
          }
        }

        .descBox {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          margin-top: 3.625rem;
          line-height: 2.0625rem;
        }
      }
    }
  }

  .contentBox {
    // border: 0.0625rem solid red;
    width: 75rem;
    margin: 1.25rem 22.5rem;
    padding-left: 2.5rem;
    display: flex;
    justify-content: space-between;

    .leftBox {
      width: 429px;
      height: 66.5625rem;

      // border: 0.0625rem solid yellow;
      .title {
        color: #000b33;
        font-size: 20px;
        font-weight: Semibold;
        height: 3.5rem;
        line-height: 3.5rem;
        // border-bottom: 1px solid #377cfd;
      }

      .search {
        width: 21rem;
        height: 3.75rem;
        // border: 0.0625rem solid red;
        margin-top: 2.5rem;
        line-height: 3.75rem;
        display: flex;

        i {
          width: 0.625rem;
          height: 0.625rem;
          color: red;
          margin-left: 1.875rem;
          // border: 1px solid red;
          margin-right: 0.625rem;
          line-height: 3.75rem;
        }

        /deep/.el-input {
          width: 18.5rem;
          // border: 0.0625rem solid red;
          background-color: transparent;
        }
      }

      .classification {
        width: 20.8681rem;
        margin-top: 1.25rem;

        .fenlei {
          height: 3.5rem;
          font-size: 1rem;
          line-height: 3.5rem;
          color: rgba(0, 11, 51, 0.8);
          // border-bottom: 1px solid #377cfd;
        }

        .fenlei:hover {
          cursor: pointer;
        }
      }

      .hotList {
        width: 22.9375rem;
        margin-top: 3.125rem;

        .title {
          width: 20.8681rem;
        }

        .hottext {
          display: flex;
          justify-content: space-between;

          .imgBox {
            img {
              width: 8.125rem;
              height: 7.5rem;
            }
          }

          .jiesao {
            // border: 0.0625rem solid yellow;
            width: 13.125rem;

            .titles {
              font-size: 1rem;
              color: rgba(0, 11, 51, 0.8);
              line-height: 1.3125rem;
              margin-top: 1.3125rem;
              margin-bottom: 0.625rem;
              height: 2.6875rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //根据业务需求设置
              overflow: hidden;
            }

            .timeBox {
              font-size: 14px;
              color: rgba(0, 11, 51, 0.8);
              line-height: 1.3125rem;
            }
          }
        }

        .hottext:hover {
          cursor: pointer;
        }
      }
    }

    .rightBox {
      width: 45.9375rem;
      // border: .0625rem solid blue;
      margin: 0 32px;

      .article {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .text {
          color: #377cfd;
        }

        .below_article {
          text-align: right;
          cursor: pointer;
        }

        .up_article {
          cursor: pointer;
        }
      }

      .details {
        width: 41.875rem;

        // border: .0625rem solid yellow;
        .tltle {
          width: 41.875rem;
          font-size: 1.875rem;
          font-weight: Semibold;
          color: #000b33;
          line-height: 2.8125rem;
          text-align: center;
          // border: 0.0625rem solid red;
          margin-top: 2.5rem;
        }

        .icoust {
          margin: 1.25rem 0;
          display: flex;

          .timeBox {
            color: #999999;
            line-height: 1.3125rem;
          }

          .laiyuan {
            color: #999999;
            line-height: 1.3125rem;
            margin: 0 1.25rem;
          }
        }

        .content {
          color: rgba(0, 11, 51, 0.8);
          line-height: 1.625rem;
        }
      }
    }

    .rightBox1 {
      width: 895px;
      // border: .0625rem solid blue;
      position: relative;
      // border: 1px solid blue;
      height: 980px;
      // display: flex;
      // flex-wrap: wrap;
      .fenlsd {
        width: 22.1875rem;
        margin-top: 1.25rem;
        float: left;
        cursor: pointer;
        // border: 1px solid red;

        .imgBox {
          img {
            width: 22.1875rem;
            height: 14.9375rem;
          }
        }

        .text {
          margin: 1.25rem 0;

          .span {
            color: #999999;
            font-size: 1rem;
            line-height: 1.3125rem;
          }

          .span:nth-child(2) {
            margin-left: 1.25rem;
          }
        }

        .nameBox {
          color: #000B33;
          font-size: 1.25rem;
          line-height: 1.625rem;
        }

        .descBox {
          margin: .625rem 0;
          height: 6.5625rem;
          // border: .0625rem solid red;
          /*1. 超出的部分隐藏 */
          overflow: hidden;

          /*2. 文字用省略号替代超出的部分 */
          text-overflow: ellipsis;

          /* 3. 弹性伸缩盒子模型显示 */
          display: -webkit-box;

          /* 4. 限制在一个块元素显示的文本的行数 */
          -webkit-line-clamp: 2;

          /* 5. 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-box-orient: vertical;

        }
      }

      .fenlsd:nth-child(even) {
        // // border: 1px solid yellow;
        margin-left: 2rem;
        // position: relative;
        // left: 1.5625rem;
      }
      .paginationBox{
        position: absolute;
        bottom: 0;
        right: 0;
        // border: 1px solid red;

      }
      .nocontent{
      // border: 1px solid red;
      font-size: 20px;
      text-align: center;
      margin-top: 100px;
      color: #999999;
    }
    }
  }
}
</style>

import http from './axios1';
// http://3uuh58.natappfree.cc/api/index/get_msg
// 公司简介
export const getindex = (params) => {
  return http({
    method: 'get',
    url: 'index/get_msg',
    params: params
  });
};

// 公司文化
export const getculture = (params) => {
  return http({
    method: 'get',
    url: 'index/get_gallery_list?cate_id=1',
    params: params
  });
};

// 企业优势
export const getadvantage = (params) => {
  return http({
    method: 'get',
    url: 'index/get_gallery_list?cate_id=2',
    params: params
  });
};

// 业务体系
export const getbusiness = (params) => {
  return http({
    method: 'get',
    url: 'index/get_gallery_list?cate_id=3',
    params: params
  });
};

// http://cms.com/api/index/get_slide?slide_id=2
// 合作资源
export const getcooperation = (params) => {
  return http({
    method: 'get',
    url: 'index/get_slide?slide_id=2',
    params: params
  });
};

// 荣誉资质
export const gethonor = (params) => {
  return http({
    method: 'get',
    url: 'index/get_slide?slide_id=3',
    params: params
  });
};

// 产品体系
export const getproductystem = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_list?cate_id=1',
    params: params
  });
};

// 新闻中心
export const getnews = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_list?cate_id=2',
    params: params
  });
};
// http://cms.com/api/index/get_article_detail?id=1
// 新闻详情
export const getnewsdetail = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_detail',
    params: params
  });
};
// 新闻分类
// http://cms.com/api/index/get_article_cate?cate_id=1
export const getclassification = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_cate',
    params: params
  });
};

// http://cms.com/api/index/get_hot_article
// 热门文章
// http://cms.com/api/index/get_article_cate?cate_id=1
export const gethotlists = (params) => {
  return http({
    method: 'get',
    url: 'index/get_hot_article',
    params: params
  });
};

// http://cms.com/api/index/get_article_list?cate_id=1&keywords=里海乡村振兴信息系统

// wen章列表
// http://cms.com/api/index/get_article_cate?cate_id=1
export const getarticlelist = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_list',
    params: params
  });
};

// 搜索wen章列表
// http://cms.com/api/index/get_article_cate?cate_id=1
export const getsearch = (params) => {
  return http({
    method: 'get',
    url: 'index/get_article_keywords',
    params: params
  });
};
// http://cms.com/api/index/get_article_keywords?keywords=里海·泸州数字农业公园&limit=10&page=1

<template>
  <div class="index">
    <div class="indexTopBox" :style="{ backgroundImage: `url(${Topimg})` }">
      <div class="indexTop">
        <div class="header">
          <div class="logo">
            <img :src="topList.logo" alt="" srcset="">
          </div>
          <div class="navigationBox">
            <div v-for="item in topList.nav" :key="item.id" class="navigation">
              <a :href="`#demo${item.id}`">{{ item.title }}</a>
            </div>
          </div>
        </div>
        <div class="introduction">
          <div class="nameBox">
            {{ topList.msg.name }}
          </div>
          <div class="keywords">
            <span v-for="eles in desc" :key="eles" class="span">{{
              eles
            }}</span>
          </div>
          <div class="descBox">
            {{ topList.msg.desc }}
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div id="demo1" class="cultureBox">
      <div class="culture">
        <div class="title">
          {{ culture.title }}
        </div>

        <div class="descBox">
          {{ culture.desc }}
        </div>
        <div class="galleryfileBox">
          <div v-for="item in culture.gallery_file" :key="item.id" class="galleryfile">
            <div class="imgBox">
              <img :src="item.filepath" alt="" srcset="">
            </div>
            <div class="nameBox">
              {{ item.title }}
            </div>
            <div class="textBox">
              <p v-for="ele in item.desc_arr" :key="ele">
                {{ ele }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业优势 -->
    <div id="demo2" class="advantageBox">
      <div class="advantage">
        <div class="title">
          {{ advantage.title }}
        </div>
        <div class="informationBpx">
          <div class="imgBox">
            <img :src="advantage.thumb" alt="" srcset="">
          </div>
          <div class="example">
            <div v-for="items in advantage.gallery_file" :key="items.id" class="advantagelz">
              <div class="imgsBox">
                <img :src="items.filepath" alt="" srcset="">
              </div>
              <div class="textaBox">
                <div class="name">{{ items.title }}</div>
                <div class="descBox">{{ items.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作资源 -->
    <div id="demo3" class="cooperationBox">
      <div class="cooperation">
        <div class="title">
          {{ cooperation.title }}
        </div>
        <div class="imgBox">
          <img :src="cooperation.image" alt="" srcset="">
        </div>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div id="demo4" class="honorBox">
      <div class="honor">
        <div class="title">
          {{ honor.title }}
        </div>
        <div class="imgBox">
          <img :src="honor.image" alt="" srcset="">
        </div>
      </div>
    </div>
    <!-- 业务体系 -->
    <div id="demo5" class="businessox">
      <div class="business">
        <div class="title">
          {{ business.title }}
        </div>
        <div class="descBox">
          {{ business.desc }}
        </div>
        <div class="navBox">
          <div class="navigation">
            <span
              v-for="(item, index) in titles"
              :key="item"
              :class="businessindex === index ? 'span' : ''"
              @click="businessnav(index)"
            >
              {{ item }}
            </span>
          </div>
          <div
            v-for="(item, index) in business.gallery_file"
            :key="item.id"
            :class="businessindex === index ? 'introduce1' : 'introduce'"
          >
            <div class="imgBox">
              <img :src="item.filepath" alt="">
            </div>
            <div class="textBox">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品体系 -->
    <div class="productystemBox">
      <div class="productystem">
        <div class="title">产品体系</div>
        <div v-for="item in productystem" :key="item.id" class="product">
          <div class="imgBox">
            <img :src="item.image" alt="" srcset="">
          </div>
          <div class="textBox">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div id="demo6" class="newsBox">
      <div class="news">
        <div class="title">新闻中心</div>
        <div class="newslistBox">
          <div v-for="item in news" :key="item.id" class="newslist" @click="gonewdeliat(item.id)">
            <div class="newslisttitle">
              <div class="text">{{ item.title }}</div>
              <div class="imgsBoxs">
                <img src="../assets/newsjt.png" alt="" srcset="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomBox" :style="{ backgroundImage: `url(${topList.msg.backimage})` }">
      <div class="bottom">
        <div class="logo">
          <img :src="topList.logo" alt="" srcset="">
        </div>
        <div class="bottomBox2">
          <div class="imgBox">
            <img :src="topList.msg.code" alt="">
            <div class="text1">扫码关注</div>
          </div>
          <div class="addressBox">
            <div class="add">{{ topList.msg.address }}</div>
            <div class="add">{{ topList.msg.email }}</div>
            <div class="add">{{ topList.msg.phone }}</div>
          </div>
        </div>
        <div class="copyright">
          {{ topList.msg.copyright }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {
  getindex,
  getculture,
  getadvantage,
  getbusiness,
  getcooperation,
  gethonor,
  getproductystem,
  getnews
} from '@/api/axiospull.js';
export default {
  components: {},
  data() {
    return {
      topList: {},
      Topimg: '',
      desc: [],
      culture: {},
      advantage: {},
      business: {},
      cooperation: {},
      honor: {},
      titles: {},
      businessindex: 0,
      productystem: {},
      news: [],
      newsindex: 0,
      newdetails: {}
    };
  },
  created() {
    this.getindex();
    this.getculture();
    this.getadvantage();
    this.getbusiness();
    this.getcooperation();
    this.gethonor();
    this.getproductystem();
    this.getnews();
    // this.getnewsdetail()
    setTimeout(() => {
      this.getLocal();
    }, 100);
  },
  mounted() {
    // this.$nextTick(() => this.getLocal());
    // this.$nextTick(() => this.getLocal());
  },
  destroyed() {
    localStorage.setItem('anchorId', '');
  },
  methods: {
    getLocal() {
      const select = localStorage.getItem('anchorId');
      console.log('xiaos', document.getElementById('demo3'));
      const elm = document.getElementById(select);
      if (select) {
        console.log('asd', elm);

        elm.scrollIntoView(true);
      }
    },
    hasClass(el, className) {
      var reg = new RegExp('(^|\\s)' + className + '(\\s|$)');
      return reg.test(el.className);
    },
    addClass(el, className) {
      if (this.hasClass(el, className)) {
        return;
      }
      var newClass = el.className.split(' ');
      newClass.push(className);
      el.className = newClass.join(' ');
    },
    businessnav(e) {
      console.log(e);
      this.businessindex = e;
      // document.getElementsByClassName('`buss${}`')
    },
    // 新闻详情跳转
    gonewdeliat(e) {
      console.log(e);
      this.$router.push({ path: '/newdalite', query: { id: e, page: 'details' }});
    },
    // 新闻中心
    async getnews() {
      const res = await getnews();
      this.news = res.data.data.data;
      console.log('新闻中心', this.news);
    },
    // 产品体系
    async getproductystem() {
      const res = await getproductystem();
      this.productystem = res.data.data.data;
      console.log('产品体系', this.productystem);
    },
    // 荣誉资质
    async gethonor() {
      const res = await gethonor();
      this.honor = res.data.data[0];
      console.log('荣誉资质', this.honor);
    },
    // 合作资源
    async getcooperation() {
      const res = await getcooperation();
      this.cooperation = res.data.data[0];
      console.log('合作资源', this.cooperation);
    },
    // 公司简介
    async getindex() {
      const res = await getindex();
      this.topList = res.data.data;
      console.log(this.topList);
      this.desc = this.topList.msg.keywords.split(',');
      console.log(this.desc);
      this.Topimg = this.topList.backgroud[0].filepath;
    },
    // 公司文化
    async getculture() {
      const res = await getculture();
      this.culture = res.data.data;
      console.log('公司文化', this.culture);
    },
    // 企业优势
    async getadvantage() {
      const res = await getadvantage();
      this.advantage = res.data.data;
      console.log('企业优势', this.advantage);
    },
    // 业务体系
    async getbusiness() {
      const res = await getbusiness();
      this.business = res.data.data;
      console.log('业务体系', this.business);
      const titles = this.business.gallery_file.map((res) => {
        return res.title;
      });
      console.log('titles', titles);
      this.titles = titles;
    }
  }
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

.index {
  width: 100%;

  // border: 1px solid red;
  // height: 3000px;
  .title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #000b33;
  }

  .indexTopBox {
    width: 100%;
    height: 67.5rem;
    border: 1px solid transparent;
    background-size: 100% 67.5rem;

    .indexTop {
      width: 108rem;
      height: 60.75rem;
      // border: 1px solid blue;
      margin: 3.375rem 6rem;

      .header {
        width: 100%;
        display: flex;

        .logo {
          width: 16.875rem;
          height: 3.3281rem;
          // border: 1px solid yellow;
        }

        .navigationBox {
          margin-left: 9.1875rem;
          width: 81.4375rem;
          // border: 1px solid yellow;
          overflow: hidden;

          .navigation {
            float: left;
            font-size: 1.25rem;
            color: #ffffff;
            margin-left: 7rem;
            height: 1.875rem;
            line-height: 1.875rem;
            // border: 1px solid red;
            padding: 0 0.3125rem;
            border-radius: 0.3125rem;
            margin-top: 0.7188rem;

            a {
              text-decoration: none;
              color: #ffffff;
            }
          }

          .navigation:nth-child(1) {
            margin-left: 0px;
          }

          .navigation:hover {
            cursor: pointer;
            background-color: #1c316d;
          }
        }
      }

      .introduction {
        width: 44.375rem;
        height: 25rem;
        // border: 1px solid yellow;
        margin-top: 15.6875rem;

        .nameBox {
          // height: 200px;
          color: #ffffff;
          font-size: 1.25rem;
          font-weight: bold;
          height: 2.5rem;
          border-bottom: 2px solid #ffffff;
          line-height: 2.5rem;
        }

        .keywords {
          font-size: 4.625rem;
          font-weight: bold;
          color: #ffffff;

          .span:nth-child(2) {
            margin-left: 3.1875rem;
          }
        }

        .descBox {
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          margin-top: 3.625rem;
          line-height: 2.0625rem;
        }
      }
    }
  }

  .cultureBox {
    border: 0.0625rem solid transparent;
    // height: 200px;
    width: 100%;

    .culture {
      width: 74.3125rem;
      // border: 1px solid yellow;
      margin: 2.8125rem 22.5rem;

      .descBox {
        width: 38.0625rem;
        height: 4.625rem;
        font-size: 1rem;
        font-family: PingFangSC-Regular-;
        color: rgba(0, 11, 51, 0.8);
        margin: 1.25rem 18.125rem;
      }

      .galleryfileBox {
        display: flex;
        justify-content: space-between;
        // border: 1px solid yellow;
        padding: 0 2.5625rem;

        .galleryfile {
          width: 14.125rem;

          // border: 1px solid blue;
          .imgBox {
            // border: 1px solid red;
            text-align: center;

            img {
              width: 3.3444rem;
              height: 3.6944rem;
            }
          }

          .nameBox {
            font-size: 1rem;
            font-weight: bold;
            color: #000b33;
            text-align: center;
            margin: 0.625rem 0;
          }

          .textBox {
            font-size: 1rem;
            font-weight: Regular;
            color: rgba(0, 11, 51, 0.7);
            line-height: 1.625rem;

            p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .advantageBox {
    // border: 1px solid blue;
    // height: 200px;
    width: 100%;

    .advantage {
      width: 74.3125rem;
      // border: 1px solid yellow;
      margin: 2.8125rem 22.5rem;

      .informationBpx {
        height: 27.0162rem;
        // border: 1px solid red;
        display: flex;
        margin-top: 2.5rem;

        .imgBox {
          width: 45.9375rem;
          height: 27.0162rem;

          // border: 1px solid blue;
          img {
            width: 45.9375rem;
            height: 27.0162rem;
          }
        }

        .example {
          .advantagelz {
            height: 50%;
            width: 28.375rem;
            // border: 1px solid blue;
            display: flex;

            .imgsBox {
              margin-left: 2.1875rem;

              img {
                width: 2.6987rem;
                height: 3rem;
              }
            }

            .textaBox {
              margin-left: 2.5625rem;

              .name {
                font-size: 1.25rem;
                color: #000b33;
                line-height: 1.625rem;
              }

              .descBox {
                font-size: 1rem;
                color: rgba(0, 11, 51, 0.7);
                line-height: 1.625rem;
                margin-top: 0.625rem;
                width: 21rem;
              }
            }
          }
        }
      }
    }
  }

  .cooperationBox {
    width: 100%;
    border: 0.0625rem solid transparent;
    margin-top: 2.5rem;

    .cooperation {
      width: 74.3125rem;
      // border: 1px solid yellow;
      margin: 2.8125rem 22.5rem;

      .imgBox {
        margin-top: 2.5rem;

        img {
          width: 100%;
          height: 27.6275rem;
        }
      }
    }
  }

  .honorBox {
    width: 100%;
    border: 0.0625rem solid transparent;
    margin-top: 2.5rem;

    .honor {
      width: 74.3125rem;
      margin: 2.8125rem 22.5rem;

      .imgBox {
        margin-top: 2.5rem;

        img {
          width: 100%;
          height: 16.375rem;
        }
      }
    }
  }

  .businessox {
    width: 100%;

    // border: 1px solid blue;
    .business {
      width: 74.3125rem;
      margin: 2.8125rem 22.5rem;

      // border: 1px solid yellow;
      .descBox {
        // border: 1px solid red;
        width: 25.125rem;
        // height: 47px;
        font-size: 1rem;
        color: rgba(0, 11, 51, 0.7);
        margin: 1.25rem 24.5938rem;
        text-align: center;
      }

      .navigation {
        width: 54.8125rem;
        height: 3.125rem;
        // border: 1px solid blue;
        line-height: 1.9375rem;
        font-size: 1.5rem;
        display: flex;
        justify-content: space-around;
        margin-bottom: 2.25rem;

        .span {
          border-bottom: 0.0625rem solid #001c80;
        }
      }

      .navigation:hover {
        cursor: pointer;
      }

      .introduce {
        display: flex;
        justify-content: space-between;
        display: none;
        // margin-top: 30px;
        // border: 1px solid red;

        .imgBox {
          img {
            width: 54.8125rem;
            height: 22.0625rem;
          }
        }

        .textBox {
          width: 17.25rem;
          // margin-left: 40px;
          font-size: 1rem;
          margin: auto 0;
          color: rgba(0, 11, 51, 0.7);
        }
      }

      .introduce1 {
        display: block;
        display: flex;
        justify-content: space-between;

        .imgBox {
          img {
            width: 54.8125rem;
            height: 22.0625rem;
          }
        }

        .textBox {
          width: 17.25rem;
          // margin-left: 40px;
          font-size: 1rem;
          margin: auto 0;
          color: rgba(0, 11, 51, 0.7);
        }
      }
    }
  }

  .productystemBox {
    width: 100%;
    border: 0.0625rem solid transparent;

    .productystem {
      width: 74.3125rem;
      margin: 45px 22.5rem;
      // border: 1px solid yellow;
      overflow: hidden;

      .product {
        float: left;
        margin-top: 2.5rem;

        .imgBox {
          img {
            width: 36.375rem;
            height: 15.59rem;
          }
        }

        .textBox {
          width: 36.375rem;
          font-size: 1rem;
          color: rgba(0, 11, 51, 0.7);
          line-height: 1.625rem;
        }
      }

      .product:hover {
        float: left;
        margin-top: 2.5rem;
        position: relative;
        top: -2rem;
        box-shadow: 0 0 .625rem #ccc;

        .imgBox {
          img {
            width: 36.375rem;
            height: 15.59rem;
          }
        }

        .textBox {
          width: 36.375rem;
          font-size: 1rem;
          color: rgba(0, 11, 51, 0.7);
          line-height: 1.625rem;
        }
      }

      .product:nth-child(odd) {
        margin-left: 1.5625rem;
      }
    }
  }

  .newsBox {
    width: 100%;

    //  border: 1px solid blue;
    .news {
      width: 74.3125rem;
      margin: 2.8125rem 22.5rem;
      // border: 1px solid yellow;
      overflow: hidden;

      .newslist {
        .newslisttitle {
          height: 1.875rem;
          font-size: 1.25rem;
          color: #333333;
          margin: 2.1875rem 0;
          // border: .0625rem solid red;
          display: flex;
          justify-content: space-between;

          .text {}

          .imgsBoxs {
            img {
              width: 1.25rem;
              height: 1.25rem;
              // border: .0625rem solid yellow;
              margin-top: 0.25rem;
            }
          }
        }

        .newslisttitle:hover {
          cursor: pointer;
          background-color: #ccc;
        }
      }
    }
  }

  .bottomBox {
    width: 100%;
    //  border: 1px solid blue;
    height: 33.28rem;
    background-size: 100% 33.28rem;

    .bottom {
      width: 26.25rem;
      // margin: 45px 360px;
      // border: 1px solid yellow;
      height: 20.4675rem;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .logo {
        width: 16.875rem;
        height: 3.3281rem;
        margin: 0 4.6875rem;
      }

      .bottomBox2 {
        margin-top: 3.25rem;
        display: flex;
        justify-content: space-between;

        // border: .0625rem solid blue;
        .imgBox {
          width: 7rem;

          // border: .0625rem solid yellow;
          img {
            width: 7rem;
            height: 7rem;
          }

          .text1 {
            text-align: center;
            font-size: 0.875rem;
            color: #ffffff;
          }
        }

        .addressBox {
          font-size: 1rem;
          color: #ffffff;

          // border: .0625rem solid red;
          .add {
            margin: 21px 0;
          }

          .add:nth-child(1) {
            margin-top: 0;
          }
        }
      }

      .copyright {
        text-align: center;
        color: #ffffff;
        font-size: 1rem;
        position: absolute;
        bottom: 0;
        left: 3.5625rem;
      }
    }
  }
}
</style>
